import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../Functionality/api';
import auth from '../Functionality/Auth';


const RegisterForm = () => {
    const location = useLocation();
    const emailInputRef = useRef(null);
    const nomeInputRef = useRef(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const myParam = params.get('email');
    if (myParam) {
      setEmail(myParam);
      emailInputRef.current.style.color = "green";
      nomeInputRef.current.focus();
    }
  }, [location.search]);

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('As senhas não coincidem. Por favor, verifique e tente novamente.');
      return;
    }

    try {
      const response = await api.post('/register', {
        action: 'register',
        full_name: fullName,
        email,
        password,
      });
      console.log('Registration successful:', response.data);
      const response2 = await api.post('/login', { email, password, action: 'login' });
      auth.login(response2.data.token)
      window.location.href = '/';
    } catch (error) {
      console.error('Registration error:', error.response.data);
      setError(error.response.data.message);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <h1 className="text-center mb-4">Registrar</h1>
          {error && <div className="alert alert-danger">{error}</div>}
          <form onSubmit={handleRegisterSubmit}>
          <div className="mb-3">
              <label htmlFor="email" className="form-label">
                E-mail
              </label>
              <input
              ref={emailInputRef}
                type="email"
                className="form-control"
                id="email"
                value={email}
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="fullName" className="form-label">
                Nome completo
              </label>
              <input
                ref={nomeInputRef}
                type="text"
                className="form-control"
                id="fullName"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                pattern="^[\p{L} .'-]+$"
                title="Por favor, insira seu nome completo."
                required
              />
            </div>
            
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Senha
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">
                Confirmar senha
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Registrar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
