import React, { useState, useEffect } from 'react';
import auth from '../Functionality/Auth';
import ExerciseList from './ExerciseList';
import api from '../Functionality/api';
import { setCache, getCache } from '../Functionality/cache';

const Dashboard = () => {
    const [showExerciseList, setShowExerciseList] = useState(false);
    const [treinosPadrao, setTreinosPadrao] = useState(null);
    const user = auth.getUserData()?.data;

    useEffect(() => {
        if (!auth.isAuthenticated()) {
          window.location.href = '/login';
        }

        async function fetchData(){
            const cachedTreinos = getCache('treinos');
            if (cachedTreinos) {
                setTreinosPadrao(cachedTreinos);
            } else { 
            try {
                const response = await api.get('/treinos', {
                    params:{  idusuario: 1, action: 'getTreinos' }});

                let tp = response.data?.treinos;
                const promises = tp.map(async t => {
                    let r = api.get('/treinos',{
                        params:{  idusuario: 1, idtreino: t.id, action: 'getExercicios' }});
                    return r;
                });
                const results = await Promise.all(promises);
                results.forEach((r,i) => {
                    tp[i]['exercicios'] = r.data?.exercicios;
                })

                setTreinosPadrao(tp);
                setCache('treinos', tp, 24*60*60*1000*30);

            } catch (error) {
                console.error('Erro:', error?.response?.data);
              }
            }
        }
        fetchData();
      }, []);


  const [selectedTreino, setSelectedTreino] = useState(null);

  const handleLogout = () => {
    auth.logout();
    window.location.href = '/';
  };
  
      
  const handleTreinoChange = (event) => {
    const treinoId = event.target.value;
    console.log("Chegou aqui: ID: " + event.target.value);
    setSelectedTreino(treinosPadrao.find((treino) => treino.id === treinoId));
    console.log(treinosPadrao);
    console.log(treinosPadrao.find((treino) => treino.id === treinoId));
  };

  const getWeekdayName = () => {
    const days = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const today = new Date();
    return days[today.getDay()];
  };

  return (
    <div className="container h-100">
        <div style={{width:"100%", display:"block", height: "50px", marginBottom: "10px"}}>
      <button
        onClick={handleLogout}
        className="btn btn-outline-danger position-fixed end-0 top-0 m-3"
      >
        Sair
      </button>
      </div>
      <div className="row h-100 justify-content-center align-items-center">
        {!showExerciseList &&
        <div className="col-12 col-md-8 text-center">
          <h1>Bem-vindo, {user.full_name}!</h1>
          <h3>Hoje é {getWeekdayName()}!</h3>
          <div className="mt-4 d-inline-block">
            <label htmlFor="treinoSelect" className="form-label">Selecione um dos seus treinos:</label>
            <select className="form-select" id="treinoSelect" onChange={handleTreinoChange}>
              <option value="">-- Selecione --</option>
              {treinosPadrao && treinosPadrao.map((treino) => (
                <option key={treino.id} value={treino.id}>
                  {treino.nome}
                </option>
              ))}
            </select>
          </div>
          {selectedTreino && (
            <>
              <div className="mt-3">
                <h5>Exercícios no treino de {selectedTreino.nome}:</h5>
                <ul style={{paddingLeft: '1rem'}}>
                  {selectedTreino.exercicios.map((exercicio, index) => (
                    <li key={index} style={{listStylePosition: 'inside'}}>{exercicio.nome}</li>
                  ))}
                </ul>
              </div>
              <div className="mt-3 d-inline-block">
                <button className="btn btn-primary" onClick={() => setShowExerciseList(true)} style={{width: 'auto'}}>
                  Iniciar Treino
                </button>
              </div>
            </>
          )}


        </div> }
        {showExerciseList && <div className='text-center'>
            <ExerciseList selectedTreino={selectedTreino} />
            <button
        onClick={()=>{ setShowExerciseList(false);setSelectedTreino(null)}}
        className="btn btn-secondary m-3"
      >
        Início
      </button>

            </div>}

      </div>
      
      
    </div>

  );
    
};

export default Dashboard;
