import { faArrowDown, faArrowUp, faDumbbell, faRedo, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import Clock from './Clock';
import { formatDate } from '../Functionality/extra';
import api from '../Functionality/api';
import { getCache, setCache } from '../Functionality/cache';


const ExerciseList = ({ selectedTreino }) => {
  const [exercises, setExercises] = useState(selectedTreino.exercicios);
  const [selectedExerciseIndex, setSelectedExerciseIndex] = useState(null);
  const [weightValue, setWeightValue] = useState(0);
  const [repsValue, setRepsValue] = useState(0);
    const weightInput = useRef(null)
    const repsInput = useRef(null)
const [seconds, setSeconds] = useState(0);

const fetchData = useCallback(async(e) =>{
  const retorno = await api.get('/treinos',{
        params: {exerciseid: e.id, action: 'getSeriesExecutadasLastDay' }});
    const novolol = selectedTreino.exercicios;
  novolol.forEach((agora, o) =>{
    if(agora.id === e.id) {
      novolol[o].lastDone = retorno.data.result;
    }
  });
  setCache("e_"+e.id, e.lastDone, 24*60*60*1000); // 1 dia de cache 
  setExercises(novolol);
}, [selectedTreino.exercicios])

useEffect(() => {
    // Seta o clock pra rodar
    let intervalId;
      intervalId = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);

//Fetchar somente se não tiver em cache
      selectedTreino.exercicios.forEach((e,i) => {
        let cached = getCache("e_"+e.id);
        if(!cached){fetchData(e)} else {
          const novolol = selectedTreino.exercicios;
          novolol[i].lastDone = cached;
          setExercises(novolol);
        }
      });

    return () => clearInterval(intervalId);
  }, [selectedTreino.exercicios, fetchData]);

  const handleReload = ()=>{
    exercises.forEach((e)=>{
      localStorage.removeItem("e_"+e.id);
      fetchData(e);
    })
  }
  //Botões de seta pra cima e pra baixo ao lado dos inputs
  const increaseValue = (setValue, currentValue) => {
    setValue(currentValue + 1);
  };
  const decreaseValue = (setValue, currentValue) => {
    if (currentValue > 0) {
      setValue(currentValue - 1);
    }
  };
  // Ficar vermelho quando o input estiver 0 
const blinkRed = (inputElement) =>{
    inputElement.style.borderColor = 'red';  
  setTimeout(function() {
    inputElement.style.borderColor = '';  
  }, 2000);
}

// Quando clica no botão +
  const handleAddSeries = (index, weight, reps) => {
    if (weight === 0){
        blinkRed(weightInput.current); return;
    } 
    if (reps === 0) {
        blinkRed(repsInput.current); return;
    } 

    const newExercises = [...exercises];
    const newSeries = { weight, reps };
    if (newExercises[index].series_done) {
      newExercises[index].series_done.push(newSeries);
    } else {
      newExercises[index].series_done = [newSeries];
    }
    sendNewSeriesToAPI(exercises[index].id, weight, reps);
    setExercises(newExercises);
  };

  // Mandar a série para o banco de dados após cada execução
  const sendNewSeriesToAPI = async(exerciseid, weight, reps) =>{
    try {
        const response = await api.post('/treinos', { exerciseid, weight, reps, action: 'addSeriesDone' });
        if(!response) throw new Error();
      } catch (error) {
        console.error('Erro:', error?.response?.data);
      }  
  }

  //Esse handleclick é para a seleção do exercício, já na lista dentro de um treino
  const handleClick = (index) => {
    setSelectedExerciseIndex(index);
    setWeightValue(0)
    setRepsValue(0)
  };

  // Botão de voltar de dentro de um exercício para a lista de exercícios de um treino selecionado
  const handleBack = () => {
    setSelectedExerciseIndex(null);
  };

  // Mostrador para as séries executadas de um exercício
  const seriesShow = (id) => {
    let exercise = exercises[id];
    if(exercise.series_done && exercise.series_done.length>0) 
        return (exercise.series_done &&
        seriesShowCommon(exercise.series_done)); else return "";
        }
const seriesShowCommon = (array) =>{
  return (array.map((series, i) => (
    <div
      className="rounded border p-1"
      key={i}
    ><span className="text-nowrap">
            <FontAwesomeIcon icon={faDumbbell} /> {series.weight}
      </span><br />
      <span className="text-nowrap"> 
          <FontAwesomeIcon icon={faRedo} /> {series.reps}
      </span>
    </div>
  )));
}
  const seriesShowBefore = (seriesResult) =>{
      if(!Array.isArray(seriesResult)) //Se não é array, provavelmente é o nome "Exercício nunca executado antes"
        return (<div style={{fontSize: '14px'}}>{seriesResult}</div>)
      else{
        return(
        <div className="d-flex flex-row align-items-center justify-content-center">
          <div style={{fontSize:'16px', marginRight:'20px'}}>
            {formatDate(seriesResult[0].data_hora)}:
          </div>
          <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
            {seriesShowCommon(seriesResult)}
          </div>
        </div>  
        );
    }
  }
       
  //Lista de exercícios mostrada após selecionar 1 treino e clicar em INICIAR
  const exerciseTable = (
    <tbody>
      {exercises.map((exercise, index) => {return(  
        <tr key={index} style={{cursor: 'pointer'}} className="clickable-row p-3 rounded border border-2 border-medium shadow-lg mb-3" onClick={() => handleClick(index)}>
          <td className="align-middle" style={{fontSize: "18px"}}>
            <div style={{fontSize:"22px", paddingBottom:"14px"}}>{exercise.nome}</div>
            <div >
            {exercise.lastDone ? seriesShowBefore(exercise.lastDone) : <>...</>}
              </div>
          </td>
          <td className="align-middle">
            {seriesShow(index)}
          </td>
        </tr>
      )}
    )}
    <tr><td colSpan="3" className="align-middle">
      <button className="btn btn-lg border border-2" style={{width:"100%",marginTop: "10px"}} onClick={handleReload}>
          <FontAwesomeIcon icon={faRedoAlt} />
        </button></td></tr>
    </tbody>
  );

  const handleRepsValueChange = (e) =>{
    setRepsValue(e.target.value)
  }
  const handleWeigthValueChange = (e) =>{
    setWeightValue(e.target.value)
  }

  // InputGroup são os inputs com botões do lado (setas cima e baixo) + o botão +
  const inputGroup = selectedExerciseIndex !== null && (
    <tbody>
      <tr>
        <td>
          <div className="input-group d-flex flex-column mt-3 text-center">
            <h4 className="mb-1 d-block">{exercises[selectedExerciseIndex].nome}</h4>
            <h6 className='d-block'>Programado: {exercises[selectedExerciseIndex].series_ideal} séries x {exercises[selectedExerciseIndex].repeticoes_ideal} reps</h6> <br />
            <div className="d-flex flex-column align-items-center justify-content-center mb-3">
              <div className="d-flex flex-row align-items-center">
              <FontAwesomeIcon style={{marginRight: "10px", height: "25px"}} icon={faDumbbell} />
                <input
                  type="number"
                  pattern="[0-9]*" 
                  className="form-control form-control-lg mb-2 me-md-2"
                  value={weightValue}
                  ref={weightInput}
                  onChange={handleWeigthValueChange}
                  style={{ textAlign: 'center' }}
                />
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-light rounded me-1"
                    onClick={(e) => {
                      e.preventDefault();
                      increaseValue(setWeightValue, weightValue);
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </button>
                  <button
                    className="btn btn-light rounded"
                    onClick={(e) => {
                      e.preventDefault();
                      decreaseValue(setWeightValue, weightValue);
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowDown} />
                  </button>
                </div>
              </div>
              <div className="d-flex flex-row  align-items-center">
              <FontAwesomeIcon style={{marginRight: "10px", height: "25px"}} icon={faRedo} />
                <input
                  type="number"
                  pattern="[0-9]*" 
                  value={repsValue}
                  ref={repsInput}
                  onChange={handleRepsValueChange}
                  className="form-control form-control-lg mb-2 me-md-2"
                  style={{ textAlign: 'center' }}
                />
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-light rounded me-1"
                    onClick={(e) => {
                      e.preventDefault();
                      increaseValue(setRepsValue, repsValue);
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </button>
                  <button
                    className="btn btn-light rounded"
                    onClick={(e) => {
                      e.preventDefault();
                      decreaseValue(setRepsValue, repsValue);
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowDown} />
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center">
              <button
                className="btn btn-primary mb-3"
                style={{ width: '150px', height: '60px', fontSize: '1.5rem' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleAddSeries(
                    selectedExerciseIndex,
                    weightValue,
                    repsValue
                  );
                }}
              >
                +
              </button>
            </div>
            <div className="aquimesmo">
                {selectedExerciseIndex !== null && seriesShow(selectedExerciseIndex)}
            </div>
      <div className="d-flex align-items-center justify-content-start">
        <button className="btn btn-secondary btn-sm" style={{marginTop: "10px"}} onClick={handleBack}>
          Voltar
        </button>
      </div>
    </div> </td></tr>
    </tbody>
  );
      

  // O principal do exerciselist, que escolhe entre exerciseTable e inputgroup pra ser mostrado
  return (<>
  <div className='table-responsive'>
    <table className="table table-borderless">
      <thead>
        <tr>
          <th colSpan="3" className="text-center">
            {
            selectedTreino.nome
            } 
            <div className="p-2" style={{textAlign: "left"}}>
              <Clock seconds={seconds}/>
            </div>
          </th>
        </tr>
      </thead>
      {selectedExerciseIndex === null ? exerciseTable : inputGroup}

    </table></div>
            </>
  );
};

export default ExerciseList;
