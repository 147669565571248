import React from "react";

function Clock(props) {
    const seconds = props.seconds || 0;
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <div>
      <h6>{formatTime(seconds)}</h6>
    </div>
  );
}

export default Clock;
