import React from 'react';

const HomePage = () => {
  return (
    <div className="container">
      <div className="row align-items-center vh-100">
        <div className="col text-center">
          <h1 className="display-4">Bem-vindo ao DN Academy</h1>
          <p className="lead">
            A plataforma que te ajuda a acompanhar seus treinos e alcançar seus
            objetivos!
          </p>
          <button className="btn btn-primary" onClick={() => window.location.href='/login'}>
            Entrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
