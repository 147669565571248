import jwtDecode from 'jwt-decode';

class Auth {
  constructor() {
    this.token = null;
  }

  login(token) {
    this.token = token;
    localStorage.setItem('userToken', token);
  }

  logout() {
    this.token = null;
    localStorage.removeItem('userToken');
  }

  isAuthenticated() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      return false;
    }
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp > currentTime;
    } catch (error) {
      console.error('Token inválido:', error);
      return false;
    }
  }

  getUserData() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      return null;
    }
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken;
    } catch (error) {
      console.error('Token inválido:', error);
      return null;
    }
  }
}

const auth = new Auth();
export default auth;
