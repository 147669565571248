const setCache = (key, value, ttl) => {
    if(value === '' ) return;
    if(value === null) return;
    if(value === undefined) return;
    const item = {
      value: JSON.stringify(value),
      expiry: Date.now() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };
  


  const getCache = (key) => {
    const itemStr = localStorage.getItem(key);
  
    if (!itemStr) {
      return null;
    }
  
    const item = JSON.parse(itemStr);
    const now = Date.now();
  
    if (now > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return JSON.parse(item.value);
  };
  
  export { setCache, getCache };
  