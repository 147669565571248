import React, { useState } from 'react';
import api from '../Functionality/api';
import auth from '../Functionality/Auth';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailChecked, setEmailChecked] = useState(false);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [error, setError] = useState('');

  const handleEmailSubmit = async (e) => {
    e.preventDefault();    

    try {
        const response = await api.post('/emailexists', { email, action: 'emailexists' });
        const isEmailRegistered = response.data.message === 'registrado';
        if (isEmailRegistered) {
            setEmailChecked(true);
            setShowRegistrationForm(false);
          } else {
            window.location.href = '/register?email='+encodeURIComponent(email);
          }
    } catch (error) {
        console.error('Erro ao checar se o email existe:', error?.response?.data);
        setError(error?.response?.data?.message);
      }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('/login', { email, password, action: 'login' });
      auth.login(response.data.token)
      window.location.href = '/';
    } catch (error) {
      console.error('Login error:', error.response.data);
      setError(error.response.data?.message);
    }
  };
  
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <h1 className="text-center mb-4">Entrar</h1>
          {!emailChecked ? (
            <form onSubmit={handleEmailSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  E-mail
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Continuar
              </button>
            </form>
          ) : !showRegistrationForm ? (
            <form onSubmit={handleLoginSubmit}>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Senha
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Entrar
              </button>
              {error && <p style={{
                color:'red',textAlign:'center'}}
                >{error}</p>}
            </form>
          ) : <></> }
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
