import React from 'react';
import HomePage from './Components/HomePage';
import Dashboard from './Components/Dashboard';
import LoginPage from './Components/LoginPage';
import auth from './Functionality/Auth.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RegisterForm from './Components/RegisterForm';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterForm />} />
      <Route path="/" element={auth.isAuthenticated() ? <Dashboard /> : <HomePage />} />
    </Routes>
  </Router>

  );
}

export default App;
